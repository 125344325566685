<template>
  <div class="reading">
    <test-introduction
      v-if="page === 0"
      :mode="mode"
      @showintroductionclick="testStart"
    ></test-introduction>
    <passage
      v-if="page === 1"
      :mode="mode"
      :question_detail="question_detail"
      @showintroductionclick="testStart"
    ></passage>
    <div class="content cover" style="width:100%" v-if="page === 2">
      <main-menu
        :showReview="showReview"
        :time="time"
        :question_detail="question_detail"
        :mode="mode"
        :showViewText="showViewText"
        @donext="getQuestion"
        @doback="getQuestion"
        @showreview="showReview = !showReview"
        @showViewText="showViewText = !showViewText"
      ></main-menu>

      <div v-show="!showReview">
        <!--非拖拽题目显示的页面-->
        <div
          class="single row mar"
          v-if="
            question_detail.type !== 'multiple' &&
              question_detail.type !== 'double'
          "
        >
          <question-default
            v-if="question_detail.type === 'default'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          ></question-default>
          <question-checkbox
            v-if="question_detail.type === 'checkbox'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            :answers="answers"
          >
          </question-checkbox>
          <question-click
            v-if="question_detail.type === 'click'"
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @insertPassage="insertPassage"
            :insertOption="insertOption"
            :answers="answers"
          ></question-click>
          <passage-default
            :question_detail="question_detail"
            :mode="mode"
            @insertAnswer="insertAnswer"
            :insertTitle="insertTitle"
            :showPassage="false"
          ></passage-default>
        </div>
        <!--拖拽排序题目显示的页面-->
        <div class="multiple" v-else>
          <question-multiple
            v-if="question_detail.type === 'multiple' && !showViewText"
            :question_detail="question_detail"
            :answers="answers"
          ></question-multiple>
          <question-double
            v-if="question_detail.type === 'double' && !showViewText"
            :question_detail="question_detail"
            :answers="answers"
          ></question-double>
          <passage-default
            v-if="showViewText"
            :question_detail="question_detail"
            :mode="mode"
            @insertAnswer="insertAnswer"
            :insertTitle="insertTitle"
            :showPassage="true"
          ></passage-default>
        </div>
      </div>
      <review-question
        v-if="showReview"
        :texts="filterReading(questions)"
        :allAnswers="allAnswers"
      ></review-question>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import TestIntroduction from "./components/TestIntroduction";
import Passage from "./components/Passage";
import MainMenu from "./components/MainMenu";
import QuestionDefault from "./components/QuestionDefault";
import QuestionClick from "./components/QuestionClick";
import PassageDefault from "./components/PassageDefault";
import QuestionMultiple from "./components/QuestionMultiple";
import QuestionDouble from "./components/QuestionDouble";
import ReviewQuestion from "./components/ReviewQuestion";
import QuestionCheckbox from "./components/QuestionCheckbox";

export default {
  metaInfo() {
    return {
      title: "TOEFL - Reading"
    };
  },

  components: {
    TestIntroduction,
    Passage,
    MainMenu,
    QuestionDefault,
    QuestionClick,
    QuestionMultiple,
    QuestionDouble,
    PassageDefault,
    ReviewQuestion,
    QuestionCheckbox
  },

  mixins: [],

  props: ["questions", "question_detail", "allAnswers", "answers", "mode"],
  data() {
    return {
      page: 0,
      showReview: false,
      showViewText: false,
      showIntroduction: true,
      user_exam: {},
      question_count: 0,
      time: {
        minute: "00",
        second: "00"
      },
      insertOption: "",
      insertTitle: "",
      questionTime: 0,
      questionTimer: null,
      timer: null
    };
  },
  computed: {
    progress_remaining() {
      return parseInt(this.time.minute) * 60 + parseInt(this.time.second);
    }
  },
  watch: {},

  mounted() {},
  destroyed() {
    //销毁各种定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.questionTimer) {
      clearInterval(this.questionTimer);
    }
  },
  methods: {
    getResolve(question_id) {
      const { href } = this.$router.resolve({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id: question_id
        }
      });
      return href;
    },
    filterReading(questions) {
      return _.filter(questions, ["skill_name", "reading"]);
    },
    //获取数据
    getQuestion(value) {
      //判断前进还是后退；
      this.$parent.getQuestion(
        value,
        this.progress_remaining,
        this.questionTime
      );
      this.insertTitle = "";
      this.questionTime = 0;
      if (this.questionTimer) {
        clearInterval(this.questionTimer);
      }
      this.questionTimer = setInterval(() => {
        this.questionTime++;
      }, 1000);
    },
    testStart() {
      this.page++;
      if (this.page === 2) {
        let question = this.questions[0]["id"];
        if (this.questions.length > 20) {
          this.countDown(3240);
        } else {
          this.time.minute = "36";
          this.countDown(2160);
        }
        this.questionTime = 0;
        this.questionTimer = setInterval(() => {
          this.questionTime++;
        }, 1000);
      }
    },
    countDown(seconds_remaining) {
      this.timer = setInterval(() => {
        var minute;
        var second;
        seconds_remaining--;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        this.time.minute = minute;
        this.time.second = second;
        if (seconds_remaining == 0) {
          seconds_remaining--;
          if (this.timer) {
            clearInterval(this.timer);
          }
          let questions = this.filterReading(this.questions);
          this.selectQuestion(questions.length - 1);
          this.$alert(
            "點擊下方繼續按鈕完成考試或跳轉到下一單元",
            "時間已用完",
            {
              showClose: false,
              confirmButtonText: "繼續",
              callback: action => {
                this.getQuestion("next");
              }
            }
          );
        }
      }, 1000);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    selectQuestion(question_index) {
      this.showReview = false;
      this.$parent.skipQuestion(question_index, this.questionTime);
    },
    insertAnswer(data) {
      this.insertOption = data;
    },
    insertPassage(data) {
      this.insertTitle = data;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
